<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>和我们，做有挑战的事！</div>
        </div>
      </div>
    </div>
    <div class="container-lg px-4 content py-5">
      <div class="col-12">
        <a
          @click="$router.go(-1)"
          role="button"
          class="btn btn-outline-primary mb-3"
        >
          返回列表
        </a>
      </div>
      <div class="col-12 title fs-1 fw-bold">商务经理</div>
      <div class="mt-3">
        <div class="badge border text-primary border-primary">市场</div>
        <div class="ms-2 badge border text-primary border-primary">
          社会招聘
        </div>
      </div>
      <div class="description mt-3">
        <div class="fs-4 fw-bold my-4">岗位描述</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              服务机构客户，以私募证券类、券商机构为主，针对所负责区域内的金融机构客户进行开发与维护，包括日常客户走访，收集客户需求，向客户提供市场咨询，与客户保持良好关系；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              根据公司金融机构业务规划，统筹金融机构整体营销方案的具体落地执行，推动金融机构开发及合作，促成多赢的谈判结果；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              根据客户需求，提供算法交易一体化解决方案。
            </li>
          </ul>
        </div>
      </div>

      <div class="requirement mt-3">
        <div class="fs-4 fw-bold my-4">岗位要求</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              学历专业不做要求，对计算机、金融科技、算法交易有深刻认知和理解加分；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              对行业经验不做要求，曾在券商、量化平台、恒生、迅投、私募、FOF机构等任职优先考虑；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              需要具有学习能力和良好的沟通能力，语言表达能力、逻辑思维能力、探索精神、主观能动性；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              具有较强的活动组织能力和抗压能力，良好的团队合作精神和协作能力均为加分项；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              拥有有券商资源，面貌形象好为加分项。
            </li>
          </ul>
        </div>
      </div>

      <div class="location mt-3">
        <div class="fs-4 fw-bold my-4">工作城市</div>
        <div class="mt-2">
          <span> 上海 </span>
          <span> 北京 </span>
          <span> 深圳 </span>
          <span> 成都 </span>
          <span> 徐州 </span>
        </div>
      </div>

      <div class="mt-3">
        <div class="fs-4 fw-bold my-4">联系方式</div>
        <div class="mb-2">简历请发送至邮箱：recruit@ft.tech</div>
        <img
          src="../../assets/img/招聘求职.jpg"
          align="center"
        />
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <a class="btn btn-primary px-5" href="mailto:recruit@ft.tech">
          简历投递
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Job8",
};
</script>
<style scoped lang="scss">
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/social_banner.jpg");
  background-position-x: 25%;
}
.container-lg {
  border-radius: 3rem;
}
a.btn {
  border-radius: 2rem;
}
img {
  width: 100px;
}
.cover-text > div {
  font-size: 3rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.75rem !important;
    text-align: center;
  }
}
</style>
